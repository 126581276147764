var autogrids = [];

$(function() {
    $(".auto-scale-in").each(function(index) {
        $(this).delay(index * 50).queue(function(next) {
            $(this).addClass("scale-in");
            next();
        });
    });
    
    $(".auto-masonry").each(function() {
        var cw = $(this).attr("colwidth");
        cw = cw ? cw : ".s12";
        var is = $(this).attr("itemselector");
        is = is ? is : ".col";
        
        var parsed = parseInt(cw);
        if (!isNaN(parsed)) {
            cw = parsed;
        }
       
        var grid = $(this).masonry({
            itemSelector: is,
            columnWidth: cw
        });
        
        autogrids.push(grid);
        
        grid.imagesLoaded().progress(function() {
            grid.masonry("layout");
        });
    });
    
    $(".matchheight").each(function() {
        $(this).children().matchHeight();
    });
});

function updategrids() {
    for (var i = 0; i < autogrids.length; ++i) {
        var grid = autogrids[i];
        grid.masonry("layout");
        grid.imagesLoaded().progress(function() {
            grid.masonry("layout");
        });
    }
}