function Dialog() {
    this.id = this.newId();
    this.mem = "";
    this.btns = "";
	this.fullscreen = false;
    this.blocked = false;
    this.escapeFunction = function() {};
}

Dialog.prototype = {
    constructor: Dialog,
    newId: function() {
        return Dialog.globalid++;
    },
    getId: function() {
        return this.id;
    },
    getNode: function() {
        return $("#dialog_" + this.id);
    },
    getInput: function(input) {
        return $("#dialog_" + this.id + "_input_" + input);
    },
	setFullScreen: function(fs) {
		this.fullscreen = fs;
		return this;
	},
    alertHtml: function(title, html, button) {
        var that = this;
        var close = function() {
            that.close();
        };

        this.build()
            .addTitle(title)
            .addHtml(html)
            .addButton(button, "close", close)
            .onEnter(close)
            .onEscape(close)
            .show();
    },
    alert: function(title, text, button) {
        var that = this;
        var close = function() {
            that.close();
        };

        this.build()
            .addTitle(title)
            .addText(text)
            .addButton(button, "close", close)
            .onEnter(close)
            .onEscape(close)
            .show();
    },
    confirm: function(title, text, btnyes, btnno, callback) {
        var that = this;
        var ok = function() {
            callback(true);
            that.close();
        }
        var cancel = function() {
            callback(false);
            that.close();
        }

        this.build()
            .addTitle(title)
            .addText(text)
            .addButton(btnyes, "yes", ok)
            .addButton(btnno, "no", cancel)
            .onEscape(cancel)
            .onEnter(ok)
            .show();
    },
    dropzone: function(title, text, btn, handler, post, callback) {
        var that = this;

        var cancel = function() {
            callback(false);
        }

        var inputs = "";
        for (var i = 0; i < post.length; i += 2) {
            inputs += `
                <input type="hidden" name="${post[i]}" value="${post[i+1]}" />`;
        }

        this.build()
            .addTitle(title)
            .addText(text)
            .addButton(btn, "cancel", cancel)
            .addHtml(`
                <form action="${handler}" class="dropzone" id="dropzone-${this.id}" enctype="multipart/form-data">
                    ${inputs}
                </form>`)
            .show()
            .onEscape(cancel)
            .onEnter(cancel);

        $("#dropzone-" + this.id).dropzone({maxFilesize: 1024});
    },
    input: function(title, text, label, preset, placeholder, btnok, btncancel, callback) {
        var that = this;

        var ok = function() {
            var res = $("#dialog_" + that.id + "_input_input").val();
            callback(res);
        }

        var cancel = function() {
            callback(false);
        }

        this.build()
            .addTitle(title)
            .addText(text)
            .addButton(btnok, "ok", ok)
            .addButton(btncancel, "cancel", cancel)
            .addInput(label, preset, placeholder, "input", ok)
            .show()
            .focus("input")
            .onEscape(cancel);

    },
    doubleInput: function(title, text, label1, pre1, placeholder1, label2, pre2, placeholder2, btnok, btncancel, callback) {
        var that = this;

        var ok = function() {
            var res1 = $("#dialog_" + that.id + "_input_input1").val();
            var res2 = $("#dialog_" + that.id + "_input_input2").val();
            callback(res1, res2, that);
        }

        var cancel = function() {
            callback(false, false);
        }

        this.build()
            .addTitle(title)
            .addText(text)
            .addButton(btnok, "ok", ok)
            .addButton(btncancel, "cancel", cancel)
            .addInput(label1, pre1, placeholder1, "input1", ok)
            .addInput(label2, pre2, placeholder2, "input2", ok)
            .show()
            .focus("input1")
            .onEscape(cancel);
    },
    loading: function(title, text) {
        this.build()
            .addTitle(title)
            .addText(text)
            .addHtml(`
                <div style="text-align: center;">
                    <img src="core/system/img/loader.gif" />
                </div>
            `)
            .show();
    },

    pObjectBrowser: function(title, text, objectClass, onSelect, onFinish, opts={}) {
        let browser_id = "dialog_pobject_browser_" + this.id;
        let browser = new PObjectBrowser(objectClass, this.id, browser_id, onSelect);
        let fullscreen = true;
        if ("fullscreen" in opts) {
            fullscreen = opts.fullscreen;
        }
        
        this.build()
            .setFullScreen(fullscreen)
            .addTitle(title)
            .addText(text)
            .addHtml(`
                <div id="${browser_id}">

                </div>
            `)
            .addButton("Fertig", "ok", onFinish)
            .onEscape(onFinish)
            .onEnter(onFinish)
            .show();
        browser.dialog = this;
        browser.browse();
    },

    fileBrowser: function(title, text, filetype, excludes, allowDuplicates, onSelect, onFinish, opts={}) {
        var folderid = "dialog_file_browser_" + this.id;
        var browser = new DialogFileBrowser(filetype, this.id, folderid, onSelect);
        browser.setExcludes(excludes);
        browser.setAllowDuplicates(allowDuplicates);

        if ("upload" in opts) {
            browser.allowUpload = opts.upload;
        }
        if ("foldercreation" in opts) {
            browser.allowFolderCreation = opts.foldercreation;
        }
        if ("extensions" in opts) {
            browser.setExtensions(opts.extensions);
        }
        if ("mimes" in opts) {
            browser.setMimes(opts.mimes);
        }
		if ("folderselection" in opts) {
			browser.selectFolder = opts.folderselection;
		}
        var fullscreen = true;
        if ("fullscreen" in opts) {
            fullscreen = opts.fullscreen;
        }
        
        this.build()
            .setFullScreen(fullscreen)
            .addTitle(title)
            .addText(text)
            .addScrollableHtml(`
                <div id="${folderid}">

                </div>
            `)
            .addButton("Fertig", "ok", onFinish)
            .onEscape(onFinish)
            .onEnter(onFinish)
            .show();

        browser.dialog = this;
		if ("folderid" in opts) {
			browser.navigate(opts.folderid);
		} else {
			browser.navigate(-1);
		}
    },
    searchSelect: function(title, text, searchlabel, searchplaceholder, elements, btncancel, callback) {
        var that = this;

        var cancel = function() {
            if (that.blocked) {
                return;
            }
            callback(false);
        };

        var enter = function() {
            if (that.blocked) {
                return;
            }
            var inputid = "dialog_" + that.id + "_input_searchinput";
            var id = filter($("#"+inputid).val());
            callback(id);
        };

        var click = function() {
            if (that.blocked) {
                return;
            }
            var id = $(this).attr("index");
            callback(id);
        };

        var filter = function(search) {
            if (that.blocked) {
                return;
            }
            var lower = search.toLowerCase();
            var ret = false;
            for (var i = 0; i < elements.length; ++i) {
                if (elements[i].toLowerCase().indexOf(lower) >= 0) {
                    // Matches
                    if (ret === false) {
                        ret = i;
                    }
                    var state = $("#search_element_"+i).attr("state");
                    if (state == "hidden") {
                    var state = $("#search_element_"+i).attr("state", "visible");
                        $("#search_element_"+i).slideDown("fast");
                    }
                } else {
                    // No match
                    var state = $("#search_element_"+i).attr("state");
                    if (state == "visible") {
                    var state = $("#search_element_"+i).attr("state", "hidden");
                        $("#search_element_"+i).slideUp("fast");
                    }
                }
            }
            return ret;
        }

        var element_divs = "";
        for (var i = 0; i < elements.length; ++i) {
            element_divs += `
                <div class="dialog_search_item dialog_search_item_${this.id}" index="${i}" id="search_element_${i}" text="${elements[i]}" state="visible">
                    ${elements[i]}
                </div>
            `;
        }

        this.build()
            .addTitle(title)
            .addText(text)
            .addButton(btncancel, "cancel", cancel)
            .addInput(searchlabel, "", searchplaceholder, "searchinput", enter)
            .addHtml(`
                <div class="dialog_search_list">
                    ${element_divs}
                </div>
            `)
            .show()
            .focus("searchinput")
            .onEscape(cancel);

        var inputid = "dialog_" + this.id + "_input_searchinput";
        $("body").on("keyup", "#" + inputid, function(e) {
            filter($("#"+inputid).val());
        });
        $("body").on("click", ".dialog_search_item_" + this.id, click);
    },
    close: function(force = false) {
        if (this.blocked && !force) {
            return;
        }
        var that = this;
		
		let tooltipped = this.getNode().find(".tooltipped");
        if (tooltipped.length > 0) {
            try {
                tooltipped.tooltip("destroy");
            } catch (e) {
                console.warn(e);
            }
        }
		
        $(document).unbind("keydown.dialog_" + this.id);
        $("#dialog_" + this.id).remove();

        if ($("#dialog_holder").children().length == 0) {
            $("#dialog_holder").hide();
        }
    },
    block: function() {
        this.blocked = true;
    },
    unblock: function() {
        this.blocked = false;
    },
    build: function() {
        this.mem = $('<div class="dialog card scale-transition scale-out" id="dialog_'+this.id+'"></div>');
        this.btns = $('<div class="dialog_buttons card-content"></div>');
        return this;
    },
    addTitle: function(title) {
        this.mem.append('<div class="dialog_title card-content blue darken-2 white-text"><span class="card-title">'+title+'</span></div>');
        return this;
    },
    addText: function(text) {
        this.mem.append('<div class="dialog_text card-content">'+text+'</div>');
        return this;
    },
    addInput: function(label, preset, placeholder, id, onEnter, type = "text") {
        var inputid = "dialog_" + this.id + "_input_" + id;
        if (typeof onEnter != "function") {
            onEnter = function() {};
        }
        
        this.addHtml(`
            <div class="input-field">
                <label ref="${inputid}" for="${inputid}" class="dialog_input_label">${label}</label>
                <input id="${inputid}" name="${inputid}" value="${preset}" type="${type}" class="dialog_input" autocomplete="new-password" />
            </div>`);
        

        var that = this;
        $("body").on("keydown", "#" + inputid, function(e) {
            if (e.which == 13) {
                onEnter();
            } else if (e.which == 27) {
                var escape = function() {
                    that.escapeFunction();
                }
                escape();
            }
        });

        return this;
    },
    getInput: function(id) {
        var inputid = "dialog_" + this.id + "_input_" + id;
        return $("#" + inputid);
    },
    addLargeButton: function(label, id, click) {
        var btnid = "dialog_" + this.id + "_btn_" + id;
        this.addHtml('<div class="dialog_button_large btn waves-effect waves-light blue white-text" id="'+btnid+'">'+label+'</div>');
        $("body").on("click", "#" + btnid, click);
        return this;
    },
    addButton: function(label, id, click) {
        var btnid = "dialog_" + this.id + "_btn_" + id;
        this.btns.append('<div class="dialog_button btn waves-effect waves-light blue white-text" id="'+btnid+'">'+label+'</div>');
        $("body").on("click", "#" + btnid, click);
        return this;
    },
    addDeleteButton: function(label, id, click) {
        var btnid = "dialog_" + this.id + "_btn_" + id;
        this.btns.append('<div class="dialog_button btn waves-effect waves-light red darken-4 white-text" id="'+btnid+'">'+label+'</div>');
        $("body").on("click", "#" + btnid, click);
        return this;
    },
    addScrollableHtml: function(html) {
        this.mem.append('<div class="card-content dialog_html dialog_html_scrollable">'+html+'</div>');
        return this;
    },
    addHtml: function(html) {
        this.mem.append('<div class="card-content dialog_html">'+html+'</div>');
        return this;
    },
    show: function() {
        this.mem.append(this.btns);
        var id = "dialog_" + this.id;
        if (!($("#dialog_holder").length)) {
            $("body").append($('<div class="dialog_holder" id="dialog_holder"></div>'));
        }
		

        $("#dialog_holder").append(this.mem);
        $("#dialog_holder").show();
        $("#dialog_" + this.id).focus();
		
		if (this.fullscreen) {
			$("#dialog_" + this.id).addClass("fullscreen");
		}
        $("#dialog_" + this.id).addClass("scale-in");
        
        M.updateTextFields();
        return this;
    },
    hide: function() {
        $("#dialog_" + this.id).hide();
        this.block();
    },
    reshow: function() {
        this.unblock();
        $("#dialog_" + this.id).show();
    },
    focus: function(input) {
        $("#dialog_" + this.id + "_input_"+input).focus();
        return this;
    },
    onEscape: function(esc) {
        var that = this;
        this.escapeFunction = esc;
        $(document).on("keydown.dialog_" + this.id, function(e) {
            if (e.which == 27 && !that.blocked) {
                esc();
            }
        });
        return this;
    },
    onEnter: function(enter) {
        var that = this;
        $(document).on("keydown.dialog_" + this.id, function(e) {
            if (e.which == 13 && !that.blocked) {
                enter();
            }
        });
        return this;
    }
}

Dialog.globalid = 0;
Dialog.isOpened = function() {
    return $("#dialog_holder").children().length > 0
}
