Dialog.prototype.editPicture = async function(pid) {
	var pic;
	try {
		var info = await $.ajax({
			url: "api/pictures/info",
			type: "POST",
			data: {
				id: pid
			}
		});
		if (!"picture" in info) {
			new Notification().error("Bild konnte nicht geladen werden");
			return this;
		}
		
		pic = info.picture;		
	} catch (e) {
		new Notification().error("Bild konnte nicht geladen werden");
		return this;
	}
	
	var h = $(window).height() * 0.7;
	
	var timewarning = "";
	if (Date.now() / 1000 - pic.uploaded > 3600) {
		// If Image older than 1 hour
		timewarning = `
			<span class="orange-text text-darken-2" style="font-weight: 600;">
				Das Bild wurde vor über einer Stunde hochgeladen. 
				Falls Sie das Bild bereits auf der Seite verwenden, empfiehlt es sich, das Bild als Kopie zu speichern.
				So vermeiden Sie fehlerhafte Anzeigen bei Nutzern.
			</span>
		`;
	}
	
	var that = this;
	
	var save = function(overwrite) {
		that.getNode().find(".dialog_button").hide();
		cropper.getCroppedCanvas({imageSmootingQuality: "high"}).toBlob((blob) => {					
			var loading = new Dialog();
			loading.loading(
				"Bitte warten",
				"Das Bild wird gespeichert..."
			);
			that.hide();
			
			const formData = new FormData();
			
			var name = pic.name;
			var fct = overwrite ? "update" : "upload";
			if (overwrite) {
				formData.append("picture", pic.id);
			} else {
				formData.append("parent", pic.parent);
				name += " (Edit [num])";
			}
			
			formData.append('upload_file', blob, name);
			
			$.ajax({
				url: 'admin/pictures/api/' + fct,
				method: "POST",
				data: formData,
				processData: false,
				contentType: false,
				success: function(resp) {
					loading.close();
					new Notification().success("Speichern erfolgreich, Sie bearbeiten nun das gespeicherte Bild");
					that.close(true);					
					pictureFolder.update();
					new Dialog().editPicture(resp.picture.id);
				},
				error: function() {
					loading.close();
					that.reshow();
					new Notification().error("Speichern fehlgeschlagen");
					that.getNode().find(".dialog_button").show();
				},
			  });
		}/*, 'image/png' */);
	}
	
	this.build()
		.setFullScreen(true)
		.addTitle("Bild bearbeiten")
		.addHtml(`
			<div style="text-align: center;">
				<img id="cropper_image" src="${pic.original}" style="max-width: 100%; max-height: ${h}px;" />
			</div>
			<div class="center-align" style="margin-top: 10px;">
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="scaleY" data-param="invert" data-tooltip="Vertikal Spiegeln" id="cropper_flipv">
					<i class="material-icons rotateR">flip</i>
				</button>				
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="scaleX" data-param="invert" data-tooltip="Horizontal Spiegeln" id="cropper_fliph">
					<i class="material-icons">flip</i>
				</button>
				<div class="btn-spacer">
				</div>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="rotate" data-param="-90" data-tooltip="Links drehen" id="cropper_rotatel">
					<i class="material-icons">rotate_left</i>
				</button>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="rotate" data-param="90" data-tooltip="Rechts drehen" id="cropper_rotater">
					<i class="material-icons">rotate_right</i>
				</button>
				<div class="btn-spacer">
				</div>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="reset" data-tooltip="Alles rückgänging" id="cropper_reset">
					<i class="material-icons">clear_all</i>
				</button>
				<div class="btn-spacer">
				</div>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="spec-ratio-original" data-tooltip="Bildformat beachten" id="cropper_ratio_original">
					<i class="material-icons">crop_original</i>
				</button>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="spec-ratio-free" data-tooltip="Freies Zuschneiden" id="cropper_ratio_free">
					<i class="material-icons">crop_free</i>
				</button>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="spec-ratio-rotate" data-tooltip="Bildformat drehen" id="cropper_ratio_rotate">
					<i class="material-icons">crop_rotate</i>
				</button>
				<div class="btn-spacer">
				</div>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="spec-ratio-num-1-1" data-tooltip="Bildformat Quadrat">
					<i class="material-icons">crop_square</i>
				</button>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="spec-ratio-num-16-9" data-tooltip="Bildformat 16:9">
					<i class="material-icons">crop_16_9</i>
				</button>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="spec-ratio-num-3-2" data-tooltip="Bildformat 3:2">
					<i class="material-icons">crop_3_2</i>
				</button>
				<button class="btn blue waves-effect tooltipped cropper_function" data-fct="spec-ratio-num-5-4" data-tooltip="Bildformat 5:4">
					<i class="material-icons">crop_5_4</i>
				</button>
			</div>
			<div class="center-align">
				${timewarning}
			</div>
		`)
		.addButton("Speichern", "save_overwrite", function() {
			save(true);			
		})
		.addButton("Speichern (Kopie)", "save_copy", function() {
			save(false);
		})
		.addButton("Abbrechen", "cancel", function() {that.close()})
		.show();
				
	const cropper = new Cropper(this.getNode().find("#cropper_image")[0], {
		viewMode: 1
	});
	
	this.getNode().find(".tooltipped").tooltip();
	console.log(cropper);
	
	
	$("body").off("click", ".cropper_function");
	$("body").on("click", ".cropper_function", function() {
		var fct = $(this).data("fct");
		var params = $(this).data("param") ? [$(this).data("param")] : []
		if ($(this).data("params")) {
			params.concat($(this).data("params").toString().split(","));
		}
		
		if (params.length == 1) {
			switch (params[0]) {
				default:
					break;
				case "invert":
					// Read value and invert it
					var data = cropper.getData(true);
					params[0] = data[fct] * -1;
					break;
			}	
		}
		
		fctp = fct.split("-");
		if (fctp[0] == "spec") {
			switch (fctp[1]) {
				case "ratio":
					var ratio = false;
					switch (fctp[2]) {
						case "num":
							cropper.setAspectRatio(fctp[3] / fctp[4]);
							break;
						case "original":
							cropper.setAspectRatio(cropper.getImageData().aspectRatio);
							break;
						case "rotate":						
							var data = cropper.getCropBoxData();
							cropper.setAspectRatio(data.height / data.width);
							break;
						case "free":
							cropper.options.aspectRatio = NaN;
							break;
					}
					break;
			}
		} else {		
			cropper[fct](...params);
		}
	});

};