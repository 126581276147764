$("body").on("click", ".history_back", function() {
    window.history.back();
});

$("body").on("click", "a", function(e) {
    var href = $(this).attr("href");
    if (typeof href !== typeof undefined && href !== false) {
        if (href.length >= 1 && href.charAt(0) == "#") {            
            if ($(this).parents(".no-anchor-scroll").length) {
                e.preventDefault();
                return;
            }
            
            if ($(href).length) {
                e.preventDefault();
                var to = $(href).offset().top;
                console.log(to);
                $("html,body").stop().animate({scrollTop: to}, 300, 'swing');
            }
        }
    }
});