DialogFileBrowser.prototype = new Folder();

function DialogFileBrowser(type, dialogid, contentid, onSelect) {
    Folder.apply(this, arguments);
    this.history = false;
    this.type = type;
    this.contentid = contentid;
    this.dialogid = dialogid;
    this.excludes = new Array();
    this.allowDuplicates = false;
    this.onSelect = onSelect;
    this.currentFiles = new Array();
    this.currentFolders = new Array();
    this.extensions = [];
    this.mimes = [];
    this.dialog = false;
    
    this.allowUpload = false;
    this.allowFolderCreation = false;
    this.allowFolderDeletion = false;
    this.allowFileDeletion = false;
	this.showPreviewOnContext = true;
    this.allowZoom = true;
    
    this.selectFolder = false;
    this.selectFile = true;
    
    this.zoom = false;

    var that = this;

    $("body").on("click", ".dialog_folder_folder_"+this.dialogid, function() {
        if (that.selectFolder && !that.selectFile && $(this).attr("type") == "folder") {
            var index = $(this).data("index");        
			var folder = that.currentFolders[index];
			folder.type = "folder";
            that.onSelect(folder, that);
            return;
        }
    
        that.navigate($(this).attr("folder"));
    });
	
	$("body").on("contextmenu", ".dialog_folder_folder_"+this.dialogid, function(e) {
        if (that.selectFolder && $(this).attr("type") == "folder") {
            var index = $(this).data("index");            
			var folder = that.currentFolders[index];
			folder.type = "folder";
            that.onSelect(folder, that);
			e.preventDefault();
            return;
        }
    });

    $("body").on("click", ".dialog_folder_file_"+this.dialogid, function() {
        var id = $(this).attr("file");
        var index = $(this).attr("index");
        if (that.excludes.indexOf(id) >= 0) {
            return;
        }
        
        if (!that.selectFile) {
            return;
        }

        if (!that.allowDuplicates) {
            that.excludes.push(id);
            var parent = $(this);
            parent.fadeTo("fast", 0, function () {
                parent.animate({
                    width: 0,
                    height: 0
                }, "fast", function () {
                    parent.remove();
                });
            });   
        }

		var file = that.currentFiles[index];
		file.type = "file";
        that.onSelect(file, that);
    });
    
    $("body").on("click", "#" + this.dialogid + "_createfolder_button", function() {
        that.hide();
        var dlg = new Dialog();
        dlg.input("Ordner erstellen",
            "Wählen Sie einen Namen für den Ordner",
            "Namen angeben:",
            "",
            "Neuer Ordner",
            "Ordner erstellen",
            "Abbrechen",
            function(res) {
                if (res === false) {
                    dlg.close();
                    that.reshow();
                    return;
                }
                if (res.length == 0) {
                    var notify = new Notification();
                    notify.info("Der Titel darf nicht leer sein");
                } else {
                    dlg.block();
                    that.createFolder(res, function(result) {
                        dlg.unblock();
                        var notify = new Notification();
                        if (result === false) {
                            notify.error("Ordner konnte nicht erstellt werden");
                        } else {
                            notify.success("Ordner wurde erstellt");
                            dlg.close();
                            that.reshow();
                            that.navigate(that.id);
                        }
                    });
                }
            });
    });
    
    $("body").on("click", "#" + this.dialogid + "_togglezoom_button", function() {
        that.zoom = !that.zoom;
        that.update();
    });
    
    $("body").on("click", "#" + this.dialogid + "_upload_button", function() {
        var title;
        var text;
        var endpoint;
        
        switch (that.type) {
            case "documents":
                title = "Dokumente hochladen";
                text = "Wählen Sie ein oder mehrere Dokumente zum hochladen aus oder ziehen Sie sie in den Upload-Bereich";
                endpoint = "admin/documents/api/upload";
                break;
            case "pictures":
                title = "Bilder hochladen";
                text = "Wählen Sie ein oder mehrere Bilder zum hochladen aus oder ziehen Sie sie in den Upload-Bereich";
                endpoint = "admin/pictures/api/upload";
                break;
            default:
                title = "Dateien hochladen";
                text = "Wählen Sie ein oder mehrere Dateien zum hochladen aus oder ziehen Sie sie in den Upload-Bereich";
                endpoint = "api/files/upload";
                break;
        }
        
        that.hide();
        var dlg = new Dialog();
        
        dlg.dropzone(
            title,
            text,
            "Fertig",
            endpoint,
            ["parent", that.id],
            function(result) {
                dlg.close();
                var notify = new Notification();
                notify.info("Upload beendet");
                that.reshow();
                that.navigate(that.id);
            }
        );
    });
	
	$("body").on("contextmenu", ".dialog_folder_file_" + this.dialogid, function(e) {
		if (!that.showPreviewOnContext) {
			return;
		}
		e.preventDefault();
		
		var file = $(this).attr("index");
		file = that.currentFiles[file];
		
		that.hide();
		var dlg = new Dialog();
		
		var close = function() {
			dlg.close();
			that.reshow();
		}
		
		dlg.build()
			.addTitle("Vorschau")
			.addHtml(`<div style="text-align: center;"><img src="${file.large}" align="center" style="max-width: 90%; max-height: 75%; margin: 0 auto;" /></div>`)
			.addButton("Ok", "ok", close)
			.onEnter(close)
			.onEscape(close)
			.show();
	});
}

DialogFileBrowser.prototype.hide = function() {
    if (this.dialog) {
        this.dialog.hide();
    }
}

DialogFileBrowser.prototype.reshow = function() {
    if (this.dialog) {
        this.dialog.reshow();
    }
}

DialogFileBrowser.prototype.constructor = DialogFileBrowser;

DialogFileBrowser.prototype.setExcludes = function(excludes) {
    this.excludes = excludes;
}

DialogFileBrowser.prototype.setExtensions = function(extensions) {
    this.extensions = extensions;
}

DialogFileBrowser.prototype.setMimes = function(mimes) {
    this.mimes = mimes;
}

DialogFileBrowser.prototype.setAllowDuplicates = function(allow) {
    this.allowDuplicates = allow;
}

DialogFileBrowser.prototype.displayFolder = function(response) {
    $("#" + this.contentid).html("");        

    if (!response.isRoot) {
        // Display parent
        $("#" + this.contentid).append(this.makeParentDiv(response.parent));
    }
    // Display Subfolders
    var subdirs = response.directories || [];
    for (var i = 0; i < subdirs.length; ++i) {
        var dir = subdirs[i];
        dir.index = i;
        $("#" + this.contentid).append(this.makeFolderDiv(dir));
    }
    var files = response.files;
    this.currentFiles = files;
    this.currentFolders = subdirs;

    for (var i = 0; i < files.length; ++i) {
        var file = files[i];
        if (this.excludes.indexOf(file.id) == -1 
            && (file.extension == "" || this.extensions.length == 0 || this.extensions.indexOf(file.extension.toLowerCase()) != -1)
            && (!("mime" in file) || file.mime == "" || this.mimes.length == 0 || this.mimes.indexOf(file.mime.toLowerCase()) != -1)
            
            ) {
            file.index = i;
            $("#" + this.contentid).append(this.makeFileDiv(file));
        }
    }
    
    var uploadButton = "";
    var createFolderButton = "";
    var toggleZoomButton = "";
    
    if (this.allowUpload) {
        uploadButton = `
            <button class="btn waves-effect blue" id="${this.dialogid}_upload_button">
                <i class="material-icons left">cloud_upload</i>
                Upload
            </button>
        `;
    }
    
    if (this.allowFolderCreation) {
        createFolderButton = `
            <button class="btn waves-effect blue" id="${this.dialogid}_createfolder_button">
                <i class="material-icons left">create_new_folder</i>
                Ordner erstellen
            </button>
        `;
    }
    
    if (this.allowZoom) {
        var icon = this.zoom ? "zoom_out" : "zoom_in";
        var zoomtext = this.zoom ? "Verkleinern" : "Vergrößern";
        toggleZoomButton = `
            <button class="btn waves-effect blue" id="${this.dialogid}_togglezoom_button">
                <i class="material-icons left">${icon}</i>
                ${zoomtext}
            </button>
        `;
    }
    
    if (this.allowUpload || this.allowFolderCreation || this.allowZoom) {
        $("#" + this.contentid).append(`
            <div class="center-align">
                ${toggleZoomButton}
                ${uploadButton}
                ${createFolderButton}
            </div>
        `);
    }
};

DialogFileBrowser.prototype.makeFileDiv = function(file) {    
    var zoom = this.zoom ? "zoomed" : "";
    var src = file.thumbnail;
    if (this.zoom && "medium" in file) {
        src = file.medium;
    }
    return `
        <div class="folder_file dialog_folder_file_${this.dialogid} ${zoom}" index="${file.index}" id="file_${file.id}" type="file" file="${file.id}" title="${file.name}" t="${file.thumbnail}">
            <div class="folder_file_content">
                <img src="${src}" /><br />
                <span id="file_name_${file.id}">${file.name}</span>
            </div>
        </div>
    `;
};


DialogFileBrowser.prototype.makeFolderDiv = function(folder) {
    var icon = '<i class="material-icons folder-icon blue-text text-darken-2">folder</i>';
    if (folder.customicon) {
        icon = `<img class="folder-icon" src="${folder.customicon}" />`;
    }
    
    var zoom = this.zoom ? "zoomed" : "";

    return `
        <div class="folder_folder dialog_folder_folder_${this.dialogid} ${zoom}" id="folder_${folder.id}" type="folder" folder="${folder.id}" title="${folder.name}" data-index="${folder.index}">
            <div class="folder_content">
                ${icon}
                <div class="folder_folder_name" id="folder_name_${folder.id}">${folder.name}</div>
            </div>
        </div>
    `;
};

DialogFileBrowser.prototype.makeParentDiv = function(id) {         
    var zoom = this.zoom ? "zoomed" : "";
    return `
        <div class="folder_folder dialog_folder_folder_${this.dialogid} ${zoom}" type="parent" folder="${id}">
            <div class="folder_content">
                <i class="material-icons folder-icon amber-text text-darken-2">folder</i>
                Übergeordnetes Verzeichnis
            </div>
        </div>
    `;
}

DialogFileBrowser.prototype.startLoad = function() {    
    this.loading = true;
    $("#" + this.contentid).css({"opacity": "0.3"});    
}

DialogFileBrowser.prototype.endLoad = function() {
    this.loading = false;
    $("#" + this.contentid).css({"opacity": "1"});    
}