class PObjectBrowser {
    constructor(objectClass, dialogId, contentId, onSelect) {
        this.objectClass = objectClass;
        this.dialogId = dialogId;
        this.contentId = contentId;
        this.onSelect = onSelect;
        this.dialog = false;
        this.container = false;

        this.availableObjects = [];
    }

    browse() {
        this.container = $(`#${this.contentId}`);
        let that = this;

        let loadingDialog = new Dialog();
        loadingDialog.loading("Bitte warten", "Objekte werden geladen...");

        $.ajax({
            "url": "api/admin/pobject_list",
            "type": "post",
            "data": {
                "class": this.objectClass
            },
            "success": function(response) {
                loadingDialog.close();
                that.availableObjects = response.objects;
                that.showBrowser();
            },
            "error": function() {
                loadingDialog.close();
                new Notification().error("Objekte konnten nicht geladen werden");
            }
        });
    }

    showBrowser() {
        this.container.html(`
            <div class="pobject-browser">

            </div>
        `);
        let browser = this.container.find(".pobject-browser");
        for (let object of this.availableObjects) {
            console.log(object);
            browser.append(`
                <div class="pobject-selector">
                    <i class="material-icons">${object.icon}</i>
                    <span class="object-id">
                        <i class="material-icons">tag</i>
                        ${object.id}
                    </span>
                    <span class="name">${object.objectName}</span>
                </div>`);
        }
    }
}