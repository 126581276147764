var DurationSelector = function(selector, preweeks, predays, prehours, preminutes) {
    this.duration = 0;
    this.selector = selector;

    $(selector).html("");
    $(selector).append(
        this.makeInput("Wochen", "weeks", preweeks)
    );
    $(selector).append(
        this.makeInput("Tage", "days", predays)
    );    
    $(selector).append(
        this.makeInput("Stunden", "hours", prehours)
    );
    $(selector).append(
        this.makeInput("Minuten", "minutes", preminutes)
    );

    var that = this;

    $(`${this.selector} > div > #minutes`).spinner({        
        spin: function (event, ui) {
            if (ui.value >= 60) {
                $(this).spinner('value', ui.value - 60);
                $(`${that.selector} > div > #hours`).spinner('stepUp');
                return false;
            } else if (ui.value < 0) {
                $(this).spinner('value', ui.value + 60);
                $(`${that.selector} > div > #hours`).spinner('stepDown');
                return false;
            }
        },
        max: 61,
        min: -1
    });

    $(`${this.selector} > div > #hours`).spinner({        
        spin: function (event, ui) {
            if (ui.value >= 24) {
                $(this).spinner('value', ui.value - 24);
                $(`${that.selector} > div > #days`).spinner('stepUp');
                return false;
            } else if (ui.value < 0) {
                $(this).spinner('value', ui.value + 24);
                $(`${that.selector} > div > #days`).spinner('stepDown');
                return false;
            }
        },
        max: 25,
        min: -1
    });

    $(`${this.selector} > div > #days`).spinner({        
        spin: function (event, ui) {
            if (ui.value >= 7) {
                $(this).spinner('value', ui.value - 7);
                $(`${that.selector} > div > #weeks`).spinner('stepUp');
                return false;
            } else if (ui.value < 0) {
                $(this).spinner('value', ui.value + 7);
                $(`${that.selector} > div > #weeks`).spinner('stepDown');
                return false;
            }
        },
        max: 8,
        min: -1
    });

    $(`${this.selector} > div > #weeks`).spinner({
        max: 1000,
        min: 0
    });
}

DurationSelector.prototype = {
    constructor: DurationSelector,
    getDuration: function() {
        this.minutes = Math.max($(`${this.selector} > div > #minutes`).val(), 0);
        this.hours   = Math.max($(`${this.selector} > div > #hours`).val(), 0);
        this.days    = Math.max($(`${this.selector} > div > #days`).val(), 0);
        this.weeks   = Math.max($(`${this.selector} > div > #weeks`).val(), 0);

        this.days += 7 * this.weeks;
        this.hours += 24 * this.days;
        this.minutes += 60 * this.hours;
        this.duration = 60 * this.minutes

        return this.duration;
    },
    makeInput: function(label, input, val) {
        if (typeof val == "undefined") {
            val = 0;
        }
        return `
            <div class="admin_pages_floating">
                <label for="${input}">${label}</label><br />
                <input type="text" value="${val}" id="${input}"/>
            </div>
        `;
    }
}

