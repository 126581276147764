function openFlipbook(images, ratio=1.3) {
    if (images.length == 0) {
        return;
    }
    
    $("#flipbookcontainer").remove();
    
    var files = [];
    
    $("body").append(`
        <div id="flipbookcontainer" style="opacity: 0;">
            <div id="flipbook_close">
                <i class="material-icons white-text circle pointer">close</i>
            </div>
            <div id="flipbook">
            </div>
        </div>
    `);
    
    var height = $("#flipbookcontainer").height() * 0.9;
    var width = height * ratio;
    
    if (width > $("#flipbookcontainer").width()) {
        // Wrong screen size
        width = $("#flipbookcontainer").width() * 0.9;
        height = width / ratio;
    }
    
    var top = ($("#flipbookcontainer").height() - height) / 2;
    var left = ($("#flipbookcontainer").width() - width) / 2;
    
    $("#flipbook").css({"margin-left": left + "px", "margin-top": top + "px"});
    
    for (var i = 0; i < images.length; ++i) {
        var dl = typeof images[i] == "object" ? images[i].download : images[i];
    
        files.push(`
            <div>
                <img src="${dl}" style="max-width: 100%; max-height: ${height}px;" class="noselect">
            </div>
        `);
    }
    
    $("#flipbookcontainer").animate({opacity: 1}, "fast");
    $("#flipbook").html(files.join("\n"));
    $("#flipbook").turn({
        autoCenter: false,
        height: height + "px",
        width: width + "px"
    });
    
    $("body").one('keydown', function(event) { 
        if (event.key == "Escape") { 
            closeFlipbook();
        } 
    });
    
    $("body").one('click', "#flipbook_close", function() { 
        closeFlipbook();
    });
};

function closeFlipbook() {
    if (!$("#flipbookcontainer").length) {
        return;
    }
    
    $("#flipbookcontainer").animate({opactiy: 0}, "fast", function() {
        $("#flipbookcontainer").remove();
    });
}