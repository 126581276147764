$("body").on("click", "a", function(e) {
    var href = $(this).attr("href") ? $(this).attr("href") : "";
    if ($(this).parent().hasClass("tab") || $(this).parents().hasClass("no-anchor-scroll")) {
        e.preventDefault();
        return;
    }
    if (href.indexOf("#") == 0) {
        if (href.length > 1) {
            e.preventDefault();
            // Scroll to anchor
            var top = $(href).offset().top;
            $("body").animate({scrollTop: top}, "fast");
            return;
        }
    }
});