function Notification() {
    this.title = "";
    this.message = "";
    this.timeout = 4000;
    this.animationTime = 1000;
    this.animationDelay = 500;
}

Notification.prototype = {
    constructor: Notification,
    notify: function(message, type) {
        M.toast({html: message, classes: type});
    },
    info: function(message) {
        this.notify(message, "blue white-text");
    },
    error: function(message) {
        this.notify(message, "red darken-4 white-text");    
    },
    success: function(message) {
        this.notify(message, "green darken-2 white-text");    
    },
    warning: function(message) {
        this.notify(message, "orange darken-4 white-text");    
    }
}